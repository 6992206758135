export default function pin({ fill = '#2568F6' }) {
  return `
  <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12 15C12.825 15 13.5313 14.7063 14.1188 14.1188C14.7063 13.5313 15 12.825 15 12C15 11.175 14.7063 10.4688 14.1188 9.88125C13.5313 9.29375 12.825 9 12 9C11.175 9 10.4688 9.29375 9.88125 9.88125C9.29375 10.4688 9 11.175 9 12C9 12.825 9.29375 13.5313 9.88125 14.1188C10.4688 14.7063 11.175 15 12 15ZM12 30C7.975 26.575 4.96875 23.3938 2.98125 20.4563C0.99375 17.5188 0 14.8 0 12.3C0 8.55 1.20625 5.5625 3.61875 3.3375C6.03125 1.1125 8.825 0 12 0C15.175 0 17.9688 1.1125 20.3813 3.3375C22.7938 5.5625 24 8.55 24 12.3C24 14.8 23.0063 17.5188 21.0188 20.4563C19.0312 23.3938 16.025 26.575 12 30Z"
    fill="${fill}"
  />
</svg>
`
}
