import React from 'react';
import { Box, Typography } from '@mui/material';

const Logo = ({ sx = {}, ...rest }) => {
  return (
    <Box {...rest} sx={{ whiteSpace: 'nowrap', textDecoration: 'none', ...sx }}>
      <Typography component='span' variant='h5' color='text.primary'>Impact</Typography>
      <Typography component='span' variant='h5' color='secondary.main'>Foresight</Typography>
    </Box>
  );
};

export default Logo;
