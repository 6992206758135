import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import AiTocTable from "./AiTocTable";
import AiTocErrorBoundary from "./AiTocErrorBoundary";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import { getElementPosition } from "utils/onboarding";
import OnboardingTooltip from "../../../common/OnboardingTooltip";
import ReplayIcon from '@mui/icons-material/Replay';
import { getHelpSteps } from "../data/helpSteps";
import AiTocFooter from "./AiTocFooter";

const AiTocResult = ({ values, refine, reset, saveToc }) => {
  const [tooltip, setTooltip] = useState({});
  const [tooltipIndex, setTooltipIndex] = useState(0);
  const tooltips = getHelpSteps();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (values.toc && Array.isArray(values.toc) && !localStorage.getItem("aiTocTooltip")) {
      showHelpTooltip();
    }
  }, []);

  const showHelpTooltip = () => {
    setTooltipIndex(0);
    showTooltip(0);
  }

  const nextTooltip = () => {
    setTooltipIndex(current => current + 1);
    showTooltip(tooltipIndex + 1);
    setTimeout(() => {
      const tooltipElement = document.getElementById('onboarding-tooltip');
      if (tooltipElement) {
        tooltipElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  const showTooltip = (index) => {
    const tooltipData = tooltips[index];
    const element = document.getElementById(tooltipData.element);
    if (element) {
      const position = getElementPosition({ current: element });
      setTooltip({ ...tooltipData, position: tooltipData.position(position) });
    }
  }

  const closeTooltip = () => {
    setTooltip({});
    setTooltipIndex(0);
    localStorage.setItem("aiTocTooltip", true);
  }

  return (
    <Box position='relative' p={{ xs: 3, sm: 4 }}>
      <AiTocErrorBoundary
        reset={reset}
        toc={values.toc}
        show={!values.toc || !Array.isArray(values.toc) || values.toc.length === 0}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between' gap={2}>
          <Typography variant='h5'>Your AI-generated theory of change</Typography>
          <Box display='flex' gap={1}>
            <Button
              variant='outlined'
              size='small'
              startIcon={!isMobileView && <ReplayIcon />}
              onClick={reset}
              sx={{ minWidth: 'unset' }}
            >
              {isMobileView ? <ReplayIcon /> : 'Start over'}
            </Button>
            <Button
              variant='outlined'
              size='small'
              startIcon={!isMobileView && <HelpOutlineOutlinedIcon />}
              onClick={showHelpTooltip}
              sx={{ minWidth: 'unset' }}
            >
              {isMobileView ? <HelpOutlineOutlinedIcon /> : 'Help'}
            </Button>
            <Button
              variant='outlined'
              size='small'
              startIcon={!isMobileView && <TuneIcon />}
              onClick={refine}
              sx={{ minWidth: 'unset' }}
            >
              {isMobileView ? <TuneIcon /> : 'Refine'}
            </Button>
          </Box>
        </Box>
        <AiTocTable toc={values.toc} />
      </AiTocErrorBoundary>
      <AiTocFooter
        values={values}
        finalStep
        saveToc={saveToc}
        showFeedbackButton={values.toc && Array.isArray(values.toc)}
      />
      <OnboardingTooltip
        open={Object.keys(tooltip).length > 0}
        onClose={closeTooltip}
        last={tooltipIndex >= tooltips.length - 1}
        next={nextTooltip}
        {...tooltip}
      />
    </Box>
  );
};

export default memo(AiTocResult);
