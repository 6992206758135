export default [
  '#EB1C2D',
  '#279B48',
  '#EF402B',
  '#00AED9',
  '#D3A029',
  '#9747FF',
  '#ADC178',
  '#EF476F',
  '#52796F',
  '#073B4C',
  '#118AB2',
  '#06D6A0'
];
