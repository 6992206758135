import React, { memo } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import ScoringOverviewPieChart from "../components/ScoringOvewrviewPieChart";
import { getVentureTotalLikelihood, getVentureTotalMagnitude, getVentureTotalScore } from "shared-components/utils/scoring";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ventureSelectors } from "store/ducks/venture";
import ScoringOverviewCard from "../components/ScoringOverviewCard";

const ScoringOverviewTotalChart = ({ selected }) => {
  const { ventureId } = useParams();
  const venture = useSelector(ventureSelectors.getCurrentVenture(ventureId));
  const magnitude = getVentureTotalMagnitude(venture, null, selected);
  const likelihood = getVentureTotalLikelihood(venture, null, selected);
  const score = getVentureTotalScore(venture, null, selected);

  return (
    <ScoringOverviewCard>
      <Typography variant='subtitleBold' align='center' sx={{ mb: 2 }}>
        Impact Potential across {selected.length} impact area{selected.length === 0 || selected.length > 1 ? 's' : ''}
      </Typography>
      <Grid container spacing={2} alignItems='center'>
        <Grid item width='30%'>
          <Typography variant='caption' align='center' sx={{ mb: 2 }}>
            5-year impact magnitude
          </Typography>
          <Box display='flex' justifyContent='center'>
            <ScoringOverviewPieChart
              value={magnitude || 0}
              max={Math.min(selected.length * 100, 500)}
              name='magnitude'
              label={`${magnitude || 0}/${Math.min(selected.length * 100, 500)}`}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Typography align='center' variant='h5' sx={{ mt: 3.5 }}>*</Typography>
        </Grid>
        <Grid item width='30%'>
          <Typography variant='caption' align='center' sx={{ mb: 2 }}>
            5-year impact likelihood
          </Typography>
          <Box display='flex' justifyContent='center'>
            <ScoringOverviewPieChart
              value={likelihood || 0}
              name='likelihood'
              label={`${likelihood || 0}%`}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Typography align='center' variant='h5' sx={{ mt: 3.5 }}>=</Typography>
        </Grid>
        <Grid item width='30%'>
          <Typography variant='caption' align='center' sx={{ mb: 2 }}>
            5-year impact potential
          </Typography>
          <Box display='flex' justifyContent='center'>
            <ScoringOverviewPieChart
              value={score || 0}
              max={Math.min(selected.length * 100, 500)}
              name='score'
              label={`${score || 0}/${Math.min(selected.length * 100, 500)}`}
            />
          </Box>
        </Grid>
      </Grid>
    </ScoringOverviewCard>
  );
};

export default memo(ScoringOverviewTotalChart);
