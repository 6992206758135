import React, { memo, useState } from 'react';
import Modal from "shared-components/views/components/modal/Modal";
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import messages from "shared-components/utils/messages";
import Box from '@mui/material/Box';
import FormikTextInput from "shared-components/views/form/FormikTextInput";
import { Autocomplete, Avatar, Typography } from '@mui/material';
import store from 'store';
import { ventureThunks } from 'store/ducks/venture';
import MemberAccessRow from "./MemberAccessRow";
import { VENTURE_ACCESS } from "shared-components/utils/constants";
import TextField from "@mui/material/TextField";
import { getTeamAccessMap, NO_ACCESS } from "utils/team";
import { toast } from "react-toastify";
import ModalActions from "shared-components/views/components/modal/ModalActions";

const InviteMemberModal = ({ open, onClose, handleSubmit, ventures, portfolios, user, values, setFieldValue }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const noAccessVentures = ventures.filter(v => values.ventureAccessMap[v.id].access === NO_ACCESS)
  const noAccessPortfolios = portfolios.filter(p => values.portfolioAccessMap[p.id].access === NO_ACCESS)

  const companyAccessAdded = (company, prop) => {
    if (company) {
      setFieldValue(prop, { ...values[prop], [company.id]: { access: VENTURE_ACCESS.EDIT } });
      setInputValue('');
      setValue(null);
    }
  }

  const changeAccess = (company, access, prop) => {
    setFieldValue(prop, { ...values[prop], [company.id]: { access } });
  }

  const revokeAccess = (company, prop) => {
    setFieldValue(prop, { ...values[prop], [company.id]: { ...values[prop][company.id], access: NO_ACCESS } });
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={user ? 'Edit member access' : 'Invite member'}
      actions={<ModalActions onClose={onClose} submitForm={handleSubmit} submitTitle='(Re)Send invitation' />}
    >
      <Box component={Form}>
        <FormikTextInput
          sx={{ mb: 2 }}
          name='name'
          placeholder='First name (optional)'
          inputProps={{ maxLength: 255 }}
          fullWidth
          disabled={!!user}
        />
        <FormikTextInput
          sx={{ mb: 2 }}
          name='lastName'
          placeholder='Last name (optional)'
          inputProps={{ maxLength: 255 }}
          fullWidth
          disabled={!!user}
        />
        <FormikTextInput
          sx={{ mb: 2 }}
          name='email'
          placeholder='Email'
          inputProps={{ maxLength: 255 }}
          fullWidth
          disabled={!!user}
        />
        <Typography align='center' variant='h5'>Ventures</Typography>
        <Box display='flex' flexDirection='column' gap={2}>
          {Object.keys(values.ventureAccessMap)
            .filter(id => values.ventureAccessMap[id].access !== NO_ACCESS)
            .map(id => (
              <MemberAccessRow
                key={id}
                company={ventures.find(v => v.id === +id)}
                access={values.ventureAccessMap[id]}
                changeAccess={(company, access) => changeAccess(company, access, 'ventureAccessMap')}
                revokeAccess={(company) => revokeAccess(company, 'ventureAccessMap')}
              />
            ))}
          {noAccessVentures.length > 0 && (
            <Box display='flex' alignItems='center' gap={2} mt={2}>
              <Typography sx={{ width: 180 }}>Grant access to:</Typography>
              <Autocomplete
                value={value}
                inputValue={inputValue}
                onChange={(e, newValue) => companyAccessAdded(newValue, 'ventureAccessMap')}
                options={noAccessVentures}
                getOptionLabel={(option) => option?.name || ''}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontSize: 20 }} key={option.name}>
                    <Box display='flex' alignItems='center' justifyContent='space-between' gap={2} width='100%'>
                      <Box display='flex' alignItems='center' gap={1}>
                        <Avatar sx={{ width: 32, height: 32 }} src={option.logo}>{option.name.slice(0, 1)}</Avatar>
                        <Typography variant='subtitleBold'>{option.name}</Typography>
                      </Box>
                      <Typography variant='subtitle' noWrap color='secondary.main'>
                        {option.website}
                      </Typography>
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant='standard' placeholder='Name' autoComplete="off" />
                )}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                fullWidth
              />
            </Box>
          )}
        </Box>

        <Typography align='center' variant='h5' sx={{ mt: 2 }}>Portfolios</Typography>
        <Box display='flex' flexDirection='column' gap={2}>
          {Object.keys(values.portfolioAccessMap)
            .filter(id => values.portfolioAccessMap[id].access !== NO_ACCESS)
            .map(id => (
              <MemberAccessRow
                key={id}
                company={portfolios.find(v => v.id === +id)}
                access={values.portfolioAccessMap[id]}
                changeAccess={(company, access) => changeAccess(company, access, 'portfolioAccessMap')}
                revokeAccess={(company) => revokeAccess(company, 'portfolioAccessMap')}
              />
            ))}
          {noAccessPortfolios.length > 0 && (
            <Box display='flex' alignItems='center' gap={2} mt={2}>
              <Typography sx={{ width: 180 }}>Grant access to:</Typography>
              <Autocomplete
                value={value}
                inputValue={inputValue}
                onChange={(e, newValue) => companyAccessAdded(newValue, 'portfolioAccessMap')}
                options={noAccessPortfolios}
                getOptionLabel={(option) => option?.name || ''}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontSize: 20 }} key={option.name}>
                    <Box display='flex' alignItems='center' justifyContent='space-between' gap={2} width='100%'>
                      <Box display='flex' alignItems='center' gap={1}>
                        <Avatar sx={{ width: 32, height: 32 }} src={option.logo}>{option.name.slice(0, 1)}</Avatar>
                        <Typography variant='subtitleBold'>{option.name}</Typography>
                      </Box>
                      <Typography variant='subtitle' noWrap color='secondary.main'>
                        {option.website}
                      </Typography>
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant='standard' placeholder='Name' autoComplete="off" />
                )}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                fullWidth
              />
            </Box>
          )}
        </Box>
        {!user && (
          <FormikTextInput
            multiline
            sx={{ mt: 2 }}
            name='message'
            placeholder='Message to the recipient (optional)'
            fullWidth
          />
        )}
      </Box>
    </Modal>
  );
};

const schema = Yup.object().shape({
  name: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().required(messages.errors.validation.required).email(messages.errors.validation.email),
  message: Yup.string(),
});

export default memo(withFormik({
  mapPropsToValues: ({ user, ventures, portfolios }) => ({
    name: user?.name || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    message: '',
    ventureAccessMap: getTeamAccessMap(ventures, user),
    portfolioAccessMap: getTeamAccessMap(portfolios, user),
  }),
  validationSchema: schema,
  enableReinitialize: true,
  handleSubmit: (data, helpers) => {
    if (helpers.props.user) {
      store.dispatch(ventureThunks.updateUserAccess({
        user: helpers.props.user,
        allVentures: helpers.props.ventures,
        ventureAccessMap: data.ventureAccessMap,
        allPortfolios: helpers.props.portfolios,
        portfolioAccessMap: data.portfolioAccessMap,
      }));
      helpers.resetForm();
      helpers.props.onClose();
    } else {
      const formValid = Object.values(data.ventureAccessMap).some(a => [VENTURE_ACCESS.VIEW, VENTURE_ACCESS.EDIT].includes(a.access))
        || Object.values(data.portfolioAccessMap).some(a => [VENTURE_ACCESS.VIEW, VENTURE_ACCESS.EDIT].includes(a.access));
      if (formValid) {
        store.dispatch(ventureThunks.inviteUser(data));
        helpers.resetForm();
        helpers.props.onClose();
      } else {
        toast.error("You need to add at least one venture or portfolio to invite a user");
      }
    }
  },
})(InviteMemberModal));
