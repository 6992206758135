import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dictionarySelectors, dictionaryThunks } from 'store/ducks/dictionary';
import { ventureThunks } from "../../store/ducks/venture";
import { userSelectors } from "../../store/ducks/user";
import Loader from "shared-components/views/components/Loader";

const AppDataContainer = ({ children }) => {
  const dispatch = useDispatch();
  const goals = useSelector(dictionarySelectors.getGoals());
  const user = useSelector(userSelectors.getCurrentUser());

  useEffect(() => {
    if (goals.length === 0) {
      dispatch(dictionaryThunks.fetchGoals());
    }
  }, [dispatch]);

  // Disable editing values in number inputs with the scroll functionality
  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.tagName.toLowerCase() === 'input' && e.target.type === 'number') {
        e.preventDefault();
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(ventureThunks.fetchVenturesAccess());
    }
  }, [dispatch, user]);

  return goals.length === 0 ? <Loader /> : children;
};

export default memo(AppDataContainer);
