import React, { memo } from 'react';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import { Grid, RadioGroup } from '@mui/material';

const FormikRadioGroup = ({ name, children, onClick, type, gridItemProps = {}, ...rest }) => {
  const [{ onChange, ...field }, state, { setValue }] = useField(name);

  const handleChange = (e) => {
    const value = e.target.value;
    if (['false', 'true'].includes(value)) {
      setValue(value === 'true');
    } else if (type === 'number') {
      setValue(Number(value));
    } else if (type === 'object') {
      setValue(JSON.parse(value));
    } else {
      setValue(value);
    }
    onClick && onClick();
  };

  const defaultGridItemProps = { xs: 12, sm: 6 };
  const itemProps = { ...defaultGridItemProps, ...gridItemProps };

  const childrenWithProps = React.Children.map(
    children,
    (child, index) => React.isValidElement(child) ?
      <Grid item key={index} {...itemProps}>
        {React.cloneElement(child, { name, index })}
      </Grid>
      : child
  );

  return (
    <>
      <RadioGroup {...field} onChange={handleChange} {...rest} name={name}>
        <Grid container spacing={1}>
          {childrenWithProps}
        </Grid>
      </RadioGroup>
      {!!state.error && state.touched && <Typography color='error' variant='caption'>{state.error}</Typography>}
    </>
  );
};

export default memo(FormikRadioGroup);
