import * as React from 'react';
import { memo } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { userSelectors, userThunks } from 'store/ducks/user';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { Form, withFormik } from 'formik';
import store from 'store';
import * as Yup from 'yup';
import messages from "shared-components/utils/messages";
import Logo from 'shared-components/views/components/Logo';
import AuthLeftPart from './AuthLeftPart';
import FormikTextInput from "shared-components/views/form/FormikTextInput";

const Login = () => {
  const user = useSelector(userSelectors.getCurrentUser());

  if (user) {
    return <Navigate to='/' replace />;
  }

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <Grid
        item
        sm={false}
        md={6}
        lg={7}
        sx={{
          backgroundColor: '#203E5C',
        }}
        display={{ xs: 'none', sm: 'none', md: 'block' }}
      >
        <AuthLeftPart />
      </Grid>
      <Grid item sm={12} md={6} lg={5} component={Paper} elevation={6} square>
        <Box mt={{ xs: 6, sm: 12 }} mx={{ xs: 3, sm: 17 }}>
          <Logo mb={{ xs: 10, sm: 33 }} />
          <Typography variant='h1'>Login</Typography>
          <Box component={Form} sx={{ mt: 1 }}>
            <FormikTextInput
              sx={{ my: 2 }}
              name='email'
              placeholder='Email address'
              autoComplete='email'
              fullWidth
            />
            <FormikTextInput
              sx={{ my: 2 }}
              name='password'
              type='password'
              placeholder='Password'
              autoComplete='current-password'
              fullWidth
            />
            <Box mt={2}>
              <Link component={RouterLink} to='/forgot' variant='subtitle'>
                Forgot password?
              </Link>
            </Box>
            <Button type='submit' fullWidth sx={{ my: 2 }}>
              Log In
            </Button>
            <Box my={2}>
              <Typography variant='subtitle'>Don't have an account?</Typography>&nbsp;
              <Link href='https://www.impactforesight.io/pricing' target='_blank' variant='subtitle'>Sign Up</Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const schema = Yup.object().shape({
  email: Yup.string().required(() => messages.errors.validation.required),
  password: Yup.string().required(() => messages.errors.validation.required)
});

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
    }),
    validationSchema: schema,
    handleSubmit: (data, helpers) => {
      store.dispatch(userThunks.login(data));
      helpers.setSubmitting(false);
    },
  })
  (Login)
);
