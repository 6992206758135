import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'configSlice',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
  },
});

export const {} = configSlice.actions;
export default configSlice.reducer;
