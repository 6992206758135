import React, { memo } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { getTypography } from "shared-components/utils/typography";
import { useSelector } from "react-redux";
import { dictionarySelectors } from "store/ducks/dictionary";

const isFirstNegative = (index, data) => {
  const firstNegativeIndex = data.findIndex(row => row.type.toLowerCase().includes('negative'));
  return index === firstNegativeIndex;
}

const StyledTable = styled(Table)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(10),
  width: '100%',
  height: '1px',
  '.MuiTableCell-root': {
    border: 'none',
    verticalAlign: 'top'
  },
  'th.MuiTableCell-root': {
    verticalAlign: 'middle',
  },
  'td.MuiTableCell-root': {
    fontSize: 12,
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(17),
  },
}));

const StyledHeaderCell = styled(TableCell)(() => ({
  ...getTypography('overline'),
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '.MuiTooltip-tooltip': {
    minWidth: 480,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      minWidth: 350,
    }
  },
}));

const getSdgTooltip = (sdg, goals) => (
  <Box display='flex' gap={2}>
    <Box display='flex' flexDirection='column' gap={1}>
      <Box
        width={112}
        height={112}
        component='img'
        src={`/images/sdg/${sdg.number}.svg`}
        alt={goals[sdg.number - 1].shortName}
        sx={{ borderRadius: '4px' }}
        flexShrink={0}
      />
      <Typography color='white' sx={{ fontSize: 32, fontWeight: 'bold' }}>
        {sdg.percent}%
      </Typography>
      <Typography color='white' sx={{ fontSize: 10 }}>
        We attribute {sdg.percent}% of this innovation impact to this SDG
      </Typography>
    </Box>
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography variant='bodyBold' color='white'>
        SDG{sdg.number}. {goals[sdg.number - 1].shortName}
      </Typography>
      <Box>
        <Typography sx={{ mb: 1 }} variant='subtitleBold' color='white'>Target</Typography>
        <Typography variant='caption' color='white'>{sdg.target}</Typography>
      </Box>
      <Box>
        <Typography sx={{ mb: 1 }} variant='subtitleBold' color='white'>Indicator</Typography>
        <Typography variant='caption' color='white'>{sdg.indicator}</Typography>
      </Box>
    </Box>
  </Box>
);

const getSdgItem = (sdg, index, goals) => (
  <Box key={index}>
    <StyledTooltip placement='top-end' title={getSdgTooltip(sdg, goals)}>
      <Box
        width={40}
        height={40}
        sx={{ borderRadius: '4px' }}
        component='img'
        src={`/images/sdg/${sdg.number}.svg`}
        alt={goals[sdg.number - 1].shortName}
      />
    </StyledTooltip>
    {sdg.percent && typeof sdg.percent === 'number' && (
      <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>{sdg.percent}%</Typography>
    )}
  </Box>
);

const AiTocTable = ({ toc }) => {
  const theme = useTheme();
  const goals = useSelector(dictionarySelectors.getGoals());
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobileView) {
    return (
      <Box mb={19} mt={2} display='flex' flexDirection='column' gap={2}>
        {toc && Array.isArray(toc) && toc.map((row, index) => (
          <Box key={index} border={1} borderColor='border'>
            <Box
              height={8}
              sx={{ backgroundColor: row.type.toLowerCase().includes('positive') ? theme.palette.success.main : theme.palette.error.main }}
              id='ai-toc-title-cell'
            />
            <Typography
              sx={{ p: 1.5 }}
              variant='captionBold'
              id={isFirstNegative(index, toc) ? 'ai-toc-first-negative' : undefined}
            >
              {row.type}: {row.title}
            </Typography>
            <Typography
              variant='overline'
              sx={{ p: 1.5, backgroundColor: 'secondary.subtle' }}
              component='div'
              id='ai-toc-status-quo-cell'
            >
              Status quo
            </Typography>
            <Typography variant='caption' sx={{ p: 1.5 }}>{row.statusQuo}</Typography>
            <Typography
              variant='overline'
              sx={{ p: 1.5, backgroundColor: 'secondary.subtle' }}
              component='div'
              id='ai-toc-innovation-cell'
            >
              Our innovation
            </Typography>
            <Typography variant='caption' sx={{ p: 1.5 }}>{row.innovation}</Typography>
            <Typography
              variant='overline'
              sx={{ p: 1.5, backgroundColor: 'secondary.subtle' }}
              component='div'
              id='ai-toc-stakeholders-cell'
            >
              Stakeholders
            </Typography>
            <Typography variant='caption' sx={{ p: 1.5 }}>{row.stakeholders}</Typography>
            <Typography
              variant='overline'
              sx={{ p: 1.5, backgroundColor: 'secondary.subtle' }}
              component='div'
              id='ai-toc-change-cell'
            >
              Stakeholder change
            </Typography>
            <Typography variant='caption' sx={{ p: 1.5 }}>{row.change}</Typography>
            <Typography
              variant='overline'
              sx={{ p: 1.5, backgroundColor: 'secondary.subtle' }}
              component='div'
              id='ai-toc-output-units-cell'
            >
              Products / services / activities
            </Typography>
            <Typography variant='caption' sx={{ p: 1.5 }}>{row.outputUnits}</Typography>
            <Typography
              variant='overline'
              sx={{ p: 1.5, backgroundColor: 'secondary.subtle' }}
              component='div'
              id='ai-toc-indicators-cell'
            >
              Indicators
            </Typography>
            <Box p={1.5} display='flex' flexDirection='column' gap={1}>
              {Array.isArray(row.indicators) && row.indicators.map((indicator, index) => {
                if (typeof indicator === 'object' && indicator.name) {
                  return <Typography key={index} variant='caption'><b>{index + 1}</b>: {indicator.name}
                  </Typography>
                } else {
                  return null;
                }
              })}
            </Box>
            <Typography
              variant='overline'
              sx={{ p: 1.5, backgroundColor: 'secondary.subtle' }}
              component='div'
              id='ai-toc-sdgs-cell'
            >
              SDGs
            </Typography>
            <Box p={1.5} display='flex' gap={1}>
              {Array.isArray(row.sdgs) && row.sdgs.map((sdg, index) => {
                if (
                  typeof sdg === 'object'
                  && sdg.number
                  && typeof sdg.number === 'number'
                  && sdg.number >= 1
                  && sdg.number <= 17
                ) {
                  return getSdgItem(sdg, index, goals);
                } else {
                  return null;
                }
              })}
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell component='th' colSpan={2}></TableCell>
          <TableCell sx={{ ...getTypography('subtitleBold') }} component='th' colSpan={2}>
            Our actions...
          </TableCell>
          <TableCell sx={{ ...getTypography('subtitleBold') }} component='th' colSpan={3}>
            Lead to change...
          </TableCell>
          <TableCell sx={{ ...getTypography('subtitleBold') }} component='th' colSpan={2}>
            ...and we provide evidence!
          </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: (theme) => theme.palette.secondary.subtle }}>
          <StyledHeaderCell
            component='th'
            sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            colSpan={2}
            id='ai-toc-title-cell'
          >
            Title
          </StyledHeaderCell>
          <StyledHeaderCell
            component='th'
            sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            id='ai-toc-status-quo-cell'
          >
            Status quo solution
          </StyledHeaderCell>
          <StyledHeaderCell
            component='th'
            sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            id='ai-toc-innovation-cell'
          >
            Our innovation
          </StyledHeaderCell>
          <StyledHeaderCell
            component='th'
            sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            id='ai-toc-stakeholders-cell'
          >
            Stakeholders
          </StyledHeaderCell>
          <StyledHeaderCell
            component='th'
            sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            id='ai-toc-change-cell'
          >
            Stakeholder change
          </StyledHeaderCell>
          <StyledHeaderCell
            component='th'
            sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            id='ai-toc-output-units-cell'
          >
            Products / services / activities
          </StyledHeaderCell>
          <StyledHeaderCell
            component='th'
            sx={{ width: '20%', fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            id='ai-toc-indicators-cell'
          >
            Indicators
          </StyledHeaderCell>
          <StyledHeaderCell
            component='th'
            sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}
            padding='none'
            id='ai-toc-sdgs-cell'
          >
            SDGs
          </StyledHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {toc && Array.isArray(toc) && toc.map((row, index) => (
          <TableRow key={index} sx={{ backgroundColor: index % 2 > 0 ? 'background.default' : '' }}>
            <TableCell padding='none' sx={{ width: 10, height: '100%' }}>
              <Box
                m={0.25}
                padding='3px'
                height='calc(100% - 4px)'
                sx={{ backgroundColor: row.type.toLowerCase().includes('positive') ? theme.palette.success.main : theme.palette.error.main }}>
              </Box>
            </TableCell>
            <TableCell
              padding='none'
              sx={{ p: 1 }}
              id={isFirstNegative(index, toc) ? 'ai-toc-first-negative' : undefined}
            >
              <b>{row.type}: {row.title}</b>
            </TableCell>
            <TableCell padding='none' sx={{ p: 1 }}>{row.statusQuo}</TableCell>
            <TableCell padding='none' sx={{ p: 1 }}>{row.innovation}</TableCell>
            <TableCell padding='none' sx={{ p: 1 }}>{row.stakeholders}</TableCell>
            <TableCell padding='none' sx={{ p: 1 }}>{row.change}</TableCell>
            <TableCell padding='none' sx={{ p: 1 }}>{row.outputUnits}</TableCell>
            <TableCell padding='none' sx={{ p: 1 }}>
              <Box display='flex' flexDirection='column' gap={1}>
                {Array.isArray(row.indicators) && row.indicators.map((indicator, index) => {
                  if (typeof indicator === 'object' && indicator.name) {
                    return <Typography key={index} variant='caption'><b>{index + 1}</b>: {indicator.name}
                    </Typography>
                  } else {
                    return null;
                  }
                })}
              </Box>
            </TableCell>
            <TableCell padding='none' sx={{ p: 1 }}>
              <Box display='flex' gap={1}>
                {Array.isArray(row.sdgs) && row.sdgs.map((sdg, index) => {
                  if (
                    typeof sdg === 'object'
                    && sdg.number
                    && typeof sdg.number === 'number'
                    && sdg.number >= 1
                    && sdg.number <= 17
                  ) {
                    return getSdgItem(sdg, index, goals);
                  } else {
                    return null;
                  }
                })}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default memo(AiTocTable);
