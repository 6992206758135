import * as React from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import PortfolioCard from './components/PortfolioCard';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from "store/ducks/user";
import { portfolioSelectors, portfolioThunks } from "store/ducks/portfolio";
import AddIcon from "@mui/icons-material/Add";
import { getElementPosition } from "utils/onboarding";
import OnboardingTooltip from "../../common/OnboardingTooltip";
import Loader from "shared-components/views/components/Loader";

const Portfolios = () => {
  const [tooltip, setTooltip] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const portfolios = useSelector(portfolioSelectors.getDetailedPortfolios());
  const isLoading = useSelector(portfolioSelectors.detailedPortfoliosLoading());
  const user = useSelector(userSelectors.getCurrentUser());
  const createPortfolioRef = useRef();

  useEffect(() => {
    dispatch(portfolioThunks.fetchPortfoliosWithDetails());
  }, []);

  useEffect(() => {
    if (createPortfolioRef.current && !localStorage.getItem('newPortfolioTooltip')) {
      setTooltip({
        position: getElementPosition(createPortfolioRef),
        title: 'Add portfolio',
        subtitle: 'Use this button to create your first portfolio',
        placement: 'left',
      });
    }
  }, [createPortfolioRef.current, isLoading]);

  const closeTooltip = () => {
    localStorage.setItem('newPortfolioTooltip', true);
    setTooltip({});
  }

  const goToPortfolio = (portfolio) => {
    localStorage.setItem('currentPortfolio', portfolio.id);
    navigate(`/portfolios/${portfolio.id}`);
  };

  const portfolioCards = portfolios.map(p => (
    <Grid item xs={12} sm={4} key={p.id}>
      <PortfolioCard
        portfolio={p}
        onClick={() => goToPortfolio(p)}
        invitation={p.members.some(a => a.member.id === user.id && a.status === 'INVITED')}
      />
    </Grid>
  ));

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Portfolios</Typography>
        <Button
          component={Link}
          to='/portfolios/profile-wizard?step=0'
          startIcon={<AddIcon />}
          size='small'
          ref={createPortfolioRef}
        >
          New portfolio
        </Button>
      </Box>
      <Grid mt={3} container spacing={3}>
        {portfolioCards}
      </Grid>
      {Object.keys(tooltip).length > 0 && (
        <OnboardingTooltip open {...tooltip} onClose={closeTooltip} />
      )}
    </Box>
  );
};

export default memo(Portfolios);
