import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';

const greetings = [
  'It is time we position impact at the core of any venture.',
  'Thank you for working with ImpactForesight.',
  'Setting impact targets will increase your impact.',
];

const AuthLeftPart = () => {
  const greetingIndex = Math.floor(Math.random() * greetings.length);

  return (
    <Box ml={12.5} mr={17.5} mt={69}>
      <Typography color='white' variant='h1'>{greetings[greetingIndex]}</Typography>
      <Typography color='white' variant='body' sx={{ mt: 3 }}>— The ImpactForesight team</Typography>
    </Box>
  );
};

export default memo(AuthLeftPart);
