import * as React from 'react';
import { memo, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ventureSelectors, ventureThunks } from 'store/ducks/venture';
import { portfolioSelectors, portfolioThunks } from "store/ducks/portfolio";
import { isDefined } from "shared-components/utils/lo";
import Loader from "shared-components/views/components/Loader";

const Home = () => {
  const dispatch = useDispatch();
  const ventures = useSelector(ventureSelectors.getVentures());
  const portfolios = useSelector(portfolioSelectors.getPortfolios());
  const isLoading = useSelector(ventureSelectors.venturesLoading()) | useSelector(portfolioSelectors.portfoliosLoading());

  useEffect(() => {
    dispatch(ventureThunks.fetchVentures());
    dispatch(portfolioThunks.fetchPortfolios());
  }, []);

  const latestView = localStorage.getItem('latestView');

  if (isLoading) {
    return <Loader />;
  } else if (!isDefined(latestView) || latestView === 'portfolio') {
    if (portfolios.length === 0) {
      return <Navigate to='/portfolios' />;
    } else {
      const portfolio = portfolios.find(v => v.id === Number(localStorage.getItem('currentPortfolio')));

      if (portfolio) {
        return <Navigate to={`/portfolios/${portfolio.id}`} />;
      } else {
        return <Navigate to='/portfolios' />;
      }
    }
  } else {
    if (ventures.length === 0) {
      return <Navigate to='/ventures' />;
    } else {
      const venture = ventures.find(v => v.id === Number(localStorage.getItem('currentVenture')));

      if (venture) {
        return <Navigate to={`/ventures/${venture.id}`} />;
      } else {
        return <Navigate to='/ventures' />;
      }
    }
  }
};

export default memo(Home);
