import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import theme from 'shared-components/theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import store from './store';
import { Provider } from 'react-redux';
import UserContainer from './views/containers/UserContainer';
import 'react-toastify/dist/ReactToastify.css';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import AppDataContainer from './views/containers/AppDataContainer';
import { RouterProvider } from 'react-router-dom';
import router from './routes/router';
import ErrorPage from "shared-components/views/pages/error/ErrorPage";
import Toaster from "shared-components/views/components/Toaster";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: window.location.hostname,
    tracesSampleRate: 0.05,
    normalizeDepth: 10
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <Box>
          <UserContainer>
            <AppDataContainer>
              <RouterProvider router={router} />
            </AppDataContainer>
          </UserContainer>
          <Toaster />
        </Box>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
