import React, { memo } from 'react';
import Card from "@mui/material/Card";
import { styled } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  minWidth: 0,
  border: `1px solid ${theme.palette.border}`,
  backgroundColor: 'white',
}));

const ScoringOverviewCard = ({ children, ...rest }) => {
  return (
    <StyledCard {...rest}>
      {children}
    </StyledCard>
  );
};

export default memo(ScoringOverviewCard);
