import React, { memo } from 'react';
import { Card, useTheme } from "@mui/material";

const PitchCard = ({ children, sx = {} }) => {
  const theme = useTheme();

  return (
    <Card sx={{ p: 2, border: `1px solid ${theme.palette.border}`, borderRadius: '8px', ...sx }}>
      {children}
    </Card>
  );
};

export default memo(PitchCard);
