import React, { memo, useState } from 'react';
import { Box, Tab, Tabs } from "@mui/material";
import PublicDatabaseVentures from "./PublicDatabaseVentures";
import PublicDatabasePortfolio from "./PublicDatabasePortfolio";
import { getTypography } from "shared-components/utils/typography";

const PublicDatabaseTabs = ({ ventures, totalVentures, setHoveredVenture, mapApp, ...rest }) => {
  const [tab, setTab] = useState('organization');

  return (
    <Box flexGrow={1} order={2} mt={{ xs: -39, sm: -46, lg: 0 }} {...rest}>
      <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
        <Tab
          sx={{
            ml: { xs: 8, xl: 0 },
            flexGrow: 1,
            maxWidth: 'unset',
            textTransform: 'none',
            ...getTypography('bodyBold'),
            '&.Mui-selected': { color: 'text.primary' }
          }}
          label='Impact ventures'
          value='organization'
        />
        <Tab
          sx={{
            flexGrow: 1,
            maxWidth: 'unset',
            textTransform: 'none',
            ...getTypography('bodyBold'),
            '&.Mui-selected': { color: 'text.primary' }
          }}
          label='Portfolio level impact'
          value='portfolio'
        />
      </Tabs>
      <Box mt={2} pr={{ xs: 0, lg: 'calc(50% - 24px)', xl: 'calc(100vw - 1050px)' }}>
        <Box backgroundColor='background' display={{ xs: 'block', lg: 'none' }} height={{ xs: 320, sm: 380 }} />
        {tab === 'organization' && (
          <PublicDatabaseVentures
            ventures={ventures}
            totalVentures={totalVentures}
            setHoveredVenture={setHoveredVenture}
            sx={{ maxHeight: { xs: 'unset', lg: 'calc(100vh - 212px)' }, overflowY: 'auto' }}
            pr={{ xs: 0, lg: 2 }}
            maxWidth={{ xs: 'unset', lg: 700 }}
            mapApp={mapApp}
          />
        )}
        {tab === 'portfolio' && (
          <PublicDatabasePortfolio
            ventures={ventures}
            totalVentures={totalVentures}
            sx={{ maxHeight: { xs: 'unset', lg: 'calc(100vh - 212px)' }, overflowY: 'auto' }}
            pr={{ xs: 0, lg: 2 }}
            maxWidth={{ xs: 'unset', lg: 700 }}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(PublicDatabaseTabs);
