import * as React from 'react';
import { memo, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { userThunks } from 'store/ducks/user';
import { Paper, Typography } from '@mui/material';
import { Form, withFormik } from 'formik';
import store from 'store';
import * as Yup from 'yup';
import messages from "shared-components/utils/messages";
import Logo from 'shared-components/views/components/Logo';
import { Link, useParams } from 'react-router-dom';
import FormikTextInput from "shared-components/views/form/FormikTextInput";
import AuthLeftPart from './AuthLeftPart';

const ResetPassword = ({ setFieldValue }) => {
  const { token } = useParams();

  useEffect(() => {
    setFieldValue('token', token);
  }, []);

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <Grid
        item
        sm={false}
        md={6}
        lg={7}
        sx={{
          backgroundColor: '#203E5C',
        }}
        display={{ xs: 'none', sm: 'none', md: 'block' }}
      >
        <AuthLeftPart />
      </Grid>
      <Grid item sm={12} md={6} lg={5} component={Paper} elevation={6} square>
        <Box mt={12} mx={17}>
          <Logo mb={33} />
          <Typography variant='h1'>Set your password</Typography>
          <Box component={Form} sx={{ mt: 1 }}>
            <FormikTextInput
              sx={{ my: 2 }}
              name='password'
              type='password'
              placeholder='Password'
              fullWidth
            />
            <FormikTextInput
              sx={{ my: 2 }}
              name='confirmPassword'
              type='password'
              placeholder='Confirm password'
              fullWidth
            />
            <Button type='submit' fullWidth sx={{ my: 2 }}>
              Change password
            </Button>
            <Button fullWidth color='secondary' component={Link} to='/forgot'>
              Request new link
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const schema = Yup.object().shape({
  token: Yup.string().required(messages.errors.validation.required),
  password: Yup.string().required(messages.errors.validation.required).min(6, messages.errors.validation.passwordMinLength),
  confirmPassword: Yup.string()
    .required(messages.errors.validation.required)
    .oneOf([Yup.ref('password')], messages.errors.validation.confirmPassword),
});

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      password: '',
      confirmPassword: '',
      token: '',
    }),
    validationSchema: schema,
    handleSubmit: (data, helpers) => {
      store.dispatch(userThunks.resetPassword(data));
      helpers.setSubmitting(false);
    },
  })
  (ResetPassword)
);
