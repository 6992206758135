import * as React from 'react';
import { memo, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '@mui/material/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors, userThunks } from 'store/ducks/user';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Avatar, Button, ListItemIcon, Menu, MenuItem, styled, Typography } from '@mui/material';
import Logo from 'shared-components/views/components/Logo';
import { HEADER_HEIGHT, VENTURE_ACCESS } from "shared-components/utils/constants";
import { ventureSelectors } from 'store/ducks/venture';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ReorderIcon from '@mui/icons-material/Reorder';
import { smallerImage } from "shared-components/utils/helpers";
import Breadcrumbs from 'shared-components/views/components/Breadcrumbs';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DvrIcon from '@mui/icons-material/Dvr';
import navigation from "shared-components/utils/navigation";
import CertificationProgress from "./CertificationProgress";
import { certificationThunks } from "../../store/ducks/certification";
import { portfolioSelectors } from "../../store/ducks/portfolio";
import LanguageIcon from '@mui/icons-material/Language';

const drawerWidth = 240;

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const Header = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { ventureId, portfolioId } = useParams();
  const venture = useSelector(ventureSelectors.getCurrentVenture(ventureId));
  const user = useSelector(userSelectors.getCurrentUser());
  const portfolio = useSelector(portfolioSelectors.getCurrentPortfolio(portfolioId));
  const access = useSelector(ventureSelectors.getVentureAccess(ventureId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (venture) {
      dispatch(certificationThunks.fetchCertificationCriteria());
    }
  }, [venture])

  const logout = () => {
    dispatch(userThunks.logout());
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Toolbar sx={{ height: HEADER_HEIGHT }} />
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} component={RouterLink} to='/user-profile'>
            <ListItemText primary='My profile' />
          </ListItemButton>
        </ListItem>
        {ventureId && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              component={RouterLink}
              to={`/ventures/${ventureId}/profile-wizard?step=0`}
              disabled={access !== VENTURE_ACCESS.EDIT}
            >
              <ListItemText primary='Edit venture profile' />
            </ListItemButton>
          </ListItem>
        )}
        {portfolioId && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              component={RouterLink}
              to={`/portfolios/${portfolioId}/profile-wizard?step=0`}
            >
              <ListItemText primary='Edit portfolio profile' />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            component={Link}
            target='_blank'
            href='https://calendly.com/impactforesight'
          >
            <ListItemText primary='Book mentor session' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} component={RouterLink} to='/portfolios'>
            <ListItemText primary='My portfolios' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} component={RouterLink} to='/ventures'>
            <ListItemText primary='My ventures' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={navigation.goToTeamManagement}>
            <ListItemText primary='Manage team' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={logout}>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box display='flex'>
      <AppBar component='nav' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar disableGutters sx={{ height: HEADER_HEIGHT, px: { xs: 2, sm: 4 }, gap: 2 }}>
          {user && (
            <IconButton
              color='default'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Logo
            component={RouterLink}
            to={portfolioId ? `/portfolios/${portfolioId}` : ventureId ? `/ventures/${ventureId}` : '/'}
            sx={{ textDecoration: 'none', pointerEvents: user ? 'auto' : 'none' }}
          />
          <Breadcrumbs display={{ xs: 'none', sm: 'flex' }} />
          <Box sx={{ flexGrow: 1 }} />
          {venture && <CertificationProgress />}
          {user && (
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }} alignItems='center' gap={2} minWidth={0}>
              <Typography component='div' color='text.primary' noWrap>
                {portfolio?.name || venture?.name || `${user?.name} ${user?.lastName}`}
              </Typography>
              <Avatar
                onClick={openMenu}
                sx={{ cursor: 'pointer', width: 32, height: 32 }}
                src={portfolio?.logo || venture?.logo || smallerImage(user.avatar)}
              />
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                onClick={closeMenu}
              >
                <StyledMenuItem component={RouterLink} to='/profile'>
                  <ListItemText primary='My profile' />
                  <ListItemIcon><PersonIcon sx={{ color: 'text.primary' }} /></ListItemIcon>
                </StyledMenuItem>
                {ventureId && <Divider />}
                {ventureId && (
                  <StyledMenuItem
                    component={RouterLink}
                    to={`/ventures/${ventureId}/profile-wizard?step=0`}
                    disabled={access !== VENTURE_ACCESS.EDIT}
                  >
                    <ListItemText primary='Edit venture profile' />
                    <ListItemIcon><EditIcon sx={{ color: 'text.primary' }} /></ListItemIcon>
                  </StyledMenuItem>
                )}
                {portfolioId && <Divider />}
                {portfolioId && (
                  <StyledMenuItem component={RouterLink} to={`/portfolios/${portfolioId}/profile-wizard?step=0`}>
                    <ListItemText primary='Edit portfolio profile' />
                    <ListItemIcon><EditIcon sx={{ color: 'text.primary' }} /></ListItemIcon>
                  </StyledMenuItem>
                )}
                <Divider />
                <StyledMenuItem
                  component={Link}
                  target='_blank'
                  href='https://calendly.com/impactforesight'
                >
                  <ListItemText primary='Book mentor session' />
                  <ListItemIcon>
                    <Box component='img' src='/images/icons/calendly.svg' width={24} height={24} />
                  </ListItemIcon>
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem component={RouterLink} to='/portfolios'>
                  <ListItemText primary='My portfolios' />
                  <ListItemIcon><DvrIcon sx={{ color: 'text.primary' }} /></ListItemIcon>
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem component={RouterLink} to='/ventures'>
                  <ListItemText primary='My ventures' />
                  <ListItemIcon><ReorderIcon sx={{ color: 'text.primary' }} /></ListItemIcon>
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem onClick={navigation.goToTeamManagement}>
                  <ListItemText primary='Manage team' />
                  <ListItemIcon><PersonAddIcon sx={{ color: 'text.primary' }} /></ListItemIcon>
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem onClick={logout}>
                  <ListItemText primary='Logout' />
                  <ListItemIcon><LogoutIcon sx={{ color: 'text.primary' }} /></ListItemIcon>
                </StyledMenuItem>
              </Menu>
            </Box>
          )}
          {!user && (
            <Button
              component={Link}
              href='https://impactforesight.io/'
              target='_blank'
              rel='noopener noreferrer'
              variant='outlined'
              startIcon={<LanguageIcon />}
            >
              Impactforesight
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {user && (
        <Box component='nav'>
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{ display: { xs: 'block', sm: 'none' }, boxSizing: 'border-box' }}
            PaperProps={{ width: drawerWidth }}
          >
            {drawer}
          </Drawer>
        </Box>
      )}
    </Box>
  );
};

export default memo(Header);
